<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('modal.resourcerequest.rejectResourceRequest.title')"
			:modelName="modelName"
			:dialogName="rejectResourceRequest"
			:onShow="onShowRejectResourceRequest"
			:onOk="onOkRejectResourceRequest"
			:showDialogProp="showDialogRejectResourceRequest"
		>
			<template slot="message" slot-scope="data">
				<v-row dense>
					<v-col>
						<pui-text-area
							:id="`refusedreason-${modelName}`"
							v-model="data.modalData.refusedreason"
							:label="$t('modal.resourcerequest.rejectResourceRequest.refusedreason')"
							required
							toplabel
							maxlength="300"
						></pui-text-area>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.resourcerequest.finishResourceRequest.title')"
			:modelName="modelName"
			:dialogName="finishResourceRequest"
			:onShow="onShowFinishResourceRequest"
			:onOk="onOkFinishResourceRequest"
			:componentHeaderName="'resourcerequest-form-header'"
			:showDialogProp="showDialogFinishResourceRequest"
		>
			<template slot="message">
				<v-row dense>
					<v-col>
						<pui-select
							:id="`visitid-${modelName}`"
							:attach="`visitid-${modelName}`"
							:label="$t('form.resourcerequest.visit')"
							toplabel
							clearable
							required
							:disabled="true"
							v-model="model"
							modelName="visit"
							:itemsToSelect="[{ id: model.visitid }]"
							:modelFormMapping="{ id: 'visitid' }"
							:itemValue="['id']"
							:itemText="(item) => `${item.portcallnumber} - ${item.vesselname} - ${item.status}`"
							:order="{ eta: 'asc' }"
							reactive
							detailComponentName="visit-form"
							detailModelName="visit"
						></pui-select>
					</v-col>
					<v-col>
						<pui-select
							:id="`stopid-${modelName}`"
							:attach="`stopid-${modelName}`"
							:label="$t('form.resourcerequest.stop')"
							toplabel
							clearable
							disabled
							v-model="model"
							modelName="stop"
							:itemsToSelect="[{ id: model.stopid }]"
							:modelFormMapping="{ id: 'stopid' }"
							itemValue="id"
							:itemText="(item) => getStopItemText(item)"
							:order="{ num: 'asc' }"
							reactive
							required
							detailComponentName="stop-form"
							detailModelName="stop"
						></pui-select>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col v-if="$store.getters.isMovementsActives">
						<pui-select
							:id="`movementid-${modelName}`"
							:attach="`movementid-${modelName}`"
							:label="$t('form.resourcerequest.movement')"
							toplabel
							clearable
							:disabled="true"
							v-model="model"
							modelName="movement"
							:itemsToSelect="[{ id: model.movementid }]"
							:modelFormMapping="{ id: 'movementid' }"
							itemValue="id"
							:itemText="(item) => `${item.nummovement} - ${item.movementtype} - ${item.movementstatus}`"
							reactive
							detailComponentName="movement-form"
							detailModelName="movement"
						></pui-select>
					</v-col>
					<v-col>
						<pui-select
							:id="`operationid-resourcerequest`"
							v-model="model"
							:label="$t('form.resourcerequest.operation')"
							:disabled="true"
							toplabel
							clearable
							:attach="`operationid-${modelName}`"
							modelName="operation"
							:itemsToSelect="[{ operationid: this.model.operationid }]"
							:modelFormMapping="{ operationid: 'operationid' }"
							itemValue="operationid"
							:itemText="
								(item) =>
									`${item.operationtype} - ${
										item.startdate ? this.$dateTimeUtils.getLocalFormattedDate(item.startdate, 'DD/MM/YYYY HH:mm') : ''
									} - ${item.enddate ? this.$dateTimeUtils.getLocalFormattedDate(item.enddate, 'DD/MM/YYYY HH:mm') : ''}`
							"
							reactive
							detailComponentName="operationform"
							detailModelName="operation"
						></pui-select>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col>
						<pui-master-detail
							componentName="resourceallocationgrid"
							:parentModelName="modelName"
							:parentPk="rrPk"
							:parentPkChildFk="{ id: 'requestid' }"
							:parentModel="model"
							:formDisabled="true"
						></pui-master-detail
					></v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.resourcerequest.optimize.title')"
			:modelName="modelName"
			:dialogName="optimize"
			:onShow="onShowOptimize"
			:onOk="onOkOptimize"
			:showDialogProp="showDialogOptimize"
			:widthDialog="700"
		>
			<template slot="message">
				<v-row dense>
					<v-col v-if="response.validconfigurationexist === true">
						<v-row class="mt-1">
							<v-col cols="1" class="d-flex align-center">
								<v-btn icon @click="previous()" :disabled="pagination <= 0">
									<v-icon>fas fa-chevron-left</v-icon>
								</v-btn>
							</v-col>
							<v-col cols="10">
								<div v-for="(config, index) in response.bestconfiguration" :key="index" v-show="index == pagination">
									<div class="text-h6">
										{{ $t('modal.resourcerequest.optimize.request') + config.descriptionrequest }}
									</div>

									<v-data-table :headers="headers" :items="config.resources" class="elevation-1" hide-default-footer>
										<template v-slot:item.marginminutes="{ item }">
											{{ item.marginminutes + ' min.' }}
										</template>
									</v-data-table>
								</div>
							</v-col>
							<v-col cols="1" class="d-flex align-center">
								<v-btn icon :disabled="response.bestconfiguration.length - 1 <= pagination" @click="next()">
									<v-icon>fas fa-chevron-right</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
					<v-col v-else-if="response.validconfigurationexist === false">{{ $t('modal.resourcerequest.optimize.noconfig') }} </v-col>
					<v-col v-else class="d-flex justify-center">
						<v-progress-circular indeterminate color="primary"></v-progress-circular>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'resourcerequestmodals',
	data() {
		return {
			rejectResourceRequest: 'rejectResourceRequest',
			showDialogRejectResourceRequest: true,
			finishResourceRequest: 'finishResourceRequest',
			showDialogFinishResourceRequest: true,
			optimize: 'optimize',
			showDialogOptimize: true,
			model: {},
			response: {},
			pagination: 0,
			headers: [
				{
					text: this.$t('modal.resourcerequest.optimize.resourceid'),
					value: 'descriptionresource',
					sortable: false
				},
				{ text: this.$t('modal.resourcerequest.optimize.marginminute'), value: 'marginminutes', sortable: false }
			]
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onShowRejectResourceRequest(modalData) {
			console.log(modalData);
		},
		onOkRejectResourceRequest(modalData) {
			const infoToEdit = {
				id: modalData.id,
				statusid: 'RE',
				refusedreason: modalData.refusedreason
			};
			return new Promise(async (resolve) => {
				await this.patchRejectResourceRequest(infoToEdit);
				resolve(true);
			});
		},
		patchRejectResourceRequest(infoToEdit) {
			if (infoToEdit && infoToEdit.refusedreason) {
				const url = this.$store.getters.getModelByName('resourcerequest').url.patch;
				const params = { statusid: infoToEdit.statusid, refusedreason: infoToEdit.refusedreason };
				const pk = { id: infoToEdit.id };
				this.doPatch(url, params, pk);
				this.showDialogRejectResourceRequest = false;
			}
		},
		onShowFinishResourceRequest(modalData) {
			this.model = modalData;
		},
		getStopItemText(item) {
			let startdate = item.ata ? item.ata : item.etaauth ? item.etaauth : item.eta;
			let enddate = item.atd ? item.atd : item.etdauth ? item.etdauth : item.etd;

			return `${item.num} - ${this.$dateTimeUtils.getLocalFormattedDate(startdate, 'DD/MM/YYYY HH:mm')}  ${
				enddate ? '-' + this.$dateTimeUtils.getLocalFormattedDate(enddate, 'DD/MM/YYYY HH:mm') : ''
			} - ${item.status}`;
		},
		onOkFinishResourceRequest() {
			const infoToEdit = {
				id: this.model.id,
				statusid: 'FI'
			};
			return new Promise(async (resolve) => {
				await this.patchFinishResourceRequest(infoToEdit);
				resolve(true);
			});
		},
		patchFinishResourceRequest(infoToEdit) {
			if (infoToEdit && infoToEdit.id) {
				const url = this.$store.getters.getModelByName('resourcerequest').url.patch;
				const params = { statusid: infoToEdit.statusid };
				const pk = { id: infoToEdit.id };
				this.doPatch(url, params, pk);
				this.showDialogFinishResourceRequest = false;
			}
		},
		doPatch(url, params, pk) {
			this.$puiRequests.patchRequest(
				url,
				params,
				(response) => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
				},
				pk
			);
		},
		onShowOptimize(modalData) {
			this.pagination = 0;
			this.response = {};
			this.$puiRequests.postRequest(
				'/resourcerequest/optimizeRequests',
				modalData.data,
				(response) => {
					this.response = response.data;
				},
				(e) => {
					this.$puiNotify.error(e.response.data.message);
				},
				null
			);
		},
		onOkOptimize() {
			return new Promise(async (resolve) => {
				this.$puiRequests.postRequest(
					'/resourcerequest/optimizeConfirm',
					this.response,
					(response) => {
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
						resolve(true);
					},
					(e) => {
						this.$puiNotify.error(e.response.data.message);
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
						resolve(false);
					},
					null
				);
			});
		},

		next() {
			if (this.response.bestconfiguration.length - 1 > this.pagination) {
				this.pagination++;
			}
		},
		previous() {
			if (this.pagination > 0) {
				this.pagination--;
			}
		}
	},
	computed: {
		rrPk() {
			return btoa(`{ "id": ${this.model.id} }`);
		}
	}
};
</script>
